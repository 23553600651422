/* eslint-disable react/prop-types */
import styled from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import React from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { get } from 'lodash';
import { Typography } from '../../components/DesignSystemV2/Typography';
import cl from 'classnames';
import { LazyImage } from '../../components/LazyImage';
import { motion } from 'framer-motion';
import Button from 'components/Button';
import { Link } from 'gatsby';

const Main = styled.section`
  margin-bottom: 24px;
  display: grid;
  gap: 24px;

  .carousel {
    .slider.animated {
      transition: all 0.8s cubic-bezier(0.5, 0, 0.75, 0) !important;
    }

    .slide {
      padding-right: 16px;
    }
  }

  .header-with-navigation {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;

    .carousel-navigation {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      gap: 10px;

      .navigate-carousel {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 10px;

        button {
          &:disabled {
            color: ${colorsV2.gray40};
          }

          padding: 0;
          border: none;
          background-color: transparent;
          min-width: 24px;
          min-height: 24px;
          width: 24px;
          height: 24px;
          display: grid;
          place-items: center;
          font-size: 24px;
          line-height: 0;
          color: ${colorsV2.black100};
          transition: 0.2s all;
          cursor: pointer;

          &:active {
            transform: scale(0.8);
          }
        }
      }
    }
  }

  .carousel-teachers {
    .teacher-image {
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid ${colorsV2.gray40};
      padding: 12px;
      display: grid;
      place-items: center;
      row-gap: 4px;
      text-align: center;
      img {
        width: 64px;
        height: 64px;
        opacity: 0.7;
      }

      :hover {
        border: 1px solid ${colorsV2.primary100};
      }

      &.active {
        background-color: ${colorsV2.primary10};
        border: 1px solid ${colorsV2.primary100};
        .card-info {
          .teacher-name {
            color: ${colorsV2.primary100};
          }
        }
        img {
          opacity: 1;
        }
      }
    }
  }

  .teacher-info {
    .teacher-info-wrapper {
      display: grid;
      gap: 16px;
      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    .teacher-highlight {
      > a {
        text-align: center;
        .cta-button {
          width: fit-content;
          margin-top: 14px;
          background: transparent;
          border-radius: 8px;
          &.full-width {
            width: 100%;
          }
        }
      }

      border-radius: 8px;
      display: grid;
      gap: 12px;

      .highlight {
        gap: 12px;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        grid-template-columns: repeat(auto-fit, minmax(0, max-content));

        .icon {
          width: 24px;
        }

        .highlight-content {
          .title {
            margin-right: 4px;
          }

          > * {
            display: inline-block;
          }
        }
      }
    }
  }
`;

export const TeachersTabMobile = ({
  data,
  activeIndex,
  setActiveIndex,
  sectionTitle,
  viewMoreUrl,
  isTeacherPage
}) => {
  const selectedTeacher = data[activeIndex];
  const dataDisplay = isTeacherPage ? data : data.slice(0, 5);

  const [sliderRef] = useKeenSlider({
    slidesPerView: 2.5,
    spacing: 12,

    breakpoints: {
      '(min-width: 776px)': {
        slidesPerView: 6.5
      }
    }
  });

  return (
    <Main className="all-dol-teachers">
      <div className="header-with-navigation">
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          {sectionTitle || 'Đội ngũ giáo viên'}
        </Typography>
      </div>

      <div ref={sliderRef} className={cl('carousel-teachers', 'keen-slider')}>
        {dataDisplay.map((item, index) => {
          return (
            <div
              className={cl('keen-slider__slide', 'teacher-image', {
                active: activeIndex === index
              })}
              onClick={() => {
                setActiveIndex(index);
              }}
              id={get(item, 'url', '').replace(/#/gi, '').toLowerCase()}
              key={item.id}
            >
              <LazyImage
                handle={item.detailImageHandler}
                alt={`${item.fullName} avatar`}
              />
              <div className="card-info">
                <Typography
                  desktopVariant="medium/20-28"
                  variant="medium/16-24"
                  className="teacher-name"
                  v3
                  color={colorsV2.black80}
                >
                  {item.fullName}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>

      <div className="teacher-info">
        {selectedTeacher && selectedTeacher.id && (
          <motion.div className="teacher-info-wrapper" key={selectedTeacher.id}>
            <div className="teacher-header">
              <LazyImage
                handle={selectedTeacher.listingImageHandler}
                alt={selectedTeacher.fullName}
              />
              <Typography variant="semi-bold/20" style={{ marginTop: '8px' }}>
                {get(selectedTeacher, 'fullName', '...')}
              </Typography>
              <Typography
                style={{ color: colorsV2.black80 }}
                variant="regular/14"
              >
                {get(selectedTeacher, 'displayNameSubtitle', '...')}
              </Typography>
            </div>
            <div className="teacher-highlight">
              {get(selectedTeacher, 'highlights', []).map(highlight => {
                const { id, title, subText, iconHandler } = highlight;
                return (
                  <div className="highlight-wrapper" key={id}>
                    <div className="highlight">
                      <div className="icon">
                        <LazyImage handle={iconHandler} alt="icon-highlight" />
                      </div>
                      <div className="highlight-content">
                        <div className="title">
                          <Typography
                            variant="semi-bold/14"
                            color={id === 'linear-badge' && colorsV2.primary100}
                          >
                            {title}
                          </Typography>
                        </div>
                        <div className="sub-text">
                          <Typography
                            style={{ color: colorsV2.black80 }}
                            variant="regular/14"
                          >
                            {subText}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {isTeacherPage ? (
                <Link to={selectedTeacher.slug}>
                  <Button outline className="cta-button full-width">
                    <Typography
                      style={{ color: colorsV2.primary100 }}
                      variant="semi-bold/14-24"
                    >
                      Xem thông tin giáo viên
                    </Typography>
                  </Button>
                </Link>
              ) : (
                <Link to={viewMoreUrl}>
                  <Button outline className="cta-button">
                    <Typography
                      style={{ color: colorsV2.primary100 }}
                      variant="semi-bold/14-24"
                    >
                      Xem đội ngũ giáo viên
                    </Typography>
                  </Button>
                </Link>
              )}
            </div>
          </motion.div>
        )}
      </div>
    </Main>
  );
};
